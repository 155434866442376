<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl w-full">

      <h2 class="text-2xl font-medium p-4 border-b-2 border-red-600">
        Relocation Package and Mobility Policy
      </h2>

      <img
        src="../../assets/img/relocation_banner.jpg"
        alt="Relocation Package and Mobility Policy Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >

      <div class="p-4">
        <p class="py-4">
          Expat remuneration in Asia has gone through much transformation
          over the years. Companies are continuously in search of simplified
          and yet effective ways of remunerating their expats of a variety
          of categories. Optimisation of resources where they are most needed
          ensure that talents are moved round the world.
        </p>
        <p class="py-2">
          MRC works together with you to:
        </p>
        <ul class="px-4 pb-4 list-disc text-red-700">
          <li>Recommend the most appropriate relocation package</li>
          <li>Localisation of expatriate packages</li>
          <li>Development of global, regional or zonal mobility polices,
            including salary, benefits, allowances, taxation, expatriation,
            repatriation and/or localisation policies.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
