import { render, staticRenderFns } from "./Relocation.vue?vue&type=template&id=219b27be&scoped=true&"
import script from "./Relocation.vue?vue&type=script&lang=js&"
export * from "./Relocation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "219b27be",
  null
  
)

export default component.exports